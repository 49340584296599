import React from "react";

const MarkerIcon = ({ colorLeft, colorRight, treeName }) => {
  return (
    <svg viewBox="0 0 290.33 313.28">
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          data-name="Unbenannter Verlauf 3"
          x1="145.16"
          y1="145.16"
          x2="289.83"
          y2="145.16"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".5" stop-color="#40b2e7" stop-opacity="0" />
          <stop offset="1" stop-color="#41b1e6" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_11"
          data-name="Unbenannter Verlauf 11"
          x1=".5"
          y1="145.16"
          x2="289.83"
          y2="145.16"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".59" stop-color="#e52720" stop-opacity="0" />
          <stop offset="1" stop-color="#e5241e" stop-opacity="0" />
        </linearGradient>
      </defs>
      <g id="Ebene_1-2" data-name="Ebene 1">
        <g>
          <path
            class="cls-1"
            style={{ fill: colorRight }}
            d="m145.16.5v289.33c79.9,0,144.66-64.77,144.66-144.66S225.06.5,145.16.5Z"
          />
          <path
            class="cls-2"
            style={{ fill: colorLeft }}
            d="m.5,145.16c0,79.9,64.77,144.66,144.66,144.66V.5C65.27.5.5,65.27.5,145.16Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            d="m253.05,140.61c0-2.07-.44-4.07-1.23-5.91.52-1.38.41-2.3-2.22-3.61-.03-.03-.05-.06-.07-.09,10.58-9.82,13.31-25.53,6.67-38.37-3.97-7.66-10.69-13.13-18.45-15.68-5.42-2.78-11.64-3.99-17.94-3.27-3.75-6.55-9.89-11.1-16.82-12.97-5.16-2.25-11.01-2.91-16.76-1.64-4.25-20.61-22.18-35.69-43.34-36.07-.72-.01-1.43,0-2.14.01-.12,0-.24-.01-.36-.01-21.42-.37-40,14.58-44.03,35.42-6.78-5.69-16.76-5.55-23.38.32-5.9,5.23-7.51,13.58-4.29,20.5.23.74.52,1.46.85,2.17-21.45-13-46.07,4.43-35.48,30.32.17,1.59.49,3.18.95,4.74-9.54,9.98-8.09,32.76,6.77,39.61-5.02,6.73-5.68,14.31-3.16,21.37.41,6.65,3.15,13.16,8.15,18.18,9.82,9.86,25.34,11.03,36.51,2.75,7.33,4.14,16.52,2.78,22.36-3.3,9.35,5.77,21.24,5.32,30.13-1.14,8.89-6.46,13.04-17.65,10.52-28.38,2.85,4.89,7.83,8.14,13.44,8.76,4.13.46,3.93-2.95,5.08-6.43.95-.35,1.85-.8,2.72-1.31,4.6,13.21,15.9,23.03,29.73,25.59,9.22,1.71,18.54-.01,26.4-4.52,5.38-2.37,10.21-5.98,14.04-10.66,1.74-.19,3.38-.76,4.84-1.64,3.89-1.03,7.06-4.05,8.14-8.08,1.42-5.29-1.16-10.84-6.14-13.21,5.21-2.5,8.52-7.73,8.52-13.47Z"
          />
          <path
            class="cls-3"
            d="m181.66,309.36c-28.69-35.84-22.05-84.22,1.04-122.91-1.1-3.69-6.08-3.66-6.08-3.66-15.15,21.47-23.64,20.8-23.47-13.14-3.71-.74-6.03-2.78-6.09-2.72,2.58,40.47-6.41,46.11-26.97,12.91-4.9-.63-4.78,4.31-4.79,6.59,26.77,31.96,26.77,82.27,6.6,124.56-1.32,2.88-2.39,2.52,9.96-1.06,6.52-2.39,11.62-1.4,15.32,2.98,15.73,2.6,15.78-9.47,34.47-3.55Z"
          />
          <path
            class="cls-3"
            d="m176.63,182.79c3.11.07,5.14,1.29,6.08,3.66-26.13,48.05-26.48,88.25-2.62,120.88.88,2.12-.42,2.35-3.9.7-4.92-.99-10.17.07-15.74,3.18-15.12-41.49-5.41-93.47,16.19-128.42Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default MarkerIcon;
